//==========================================================
// Web Service BaseURL
//==========================================================


const TimeZone = {
    ukTimeZone: "Europe/London",
    pkTimeZone: "Asia/Karachi",
	aeTimeZone: "Asia/Dubai"
};

 const BaseURLs = {
      coreApiURL : "https://api.supermeal.ae/api",
    baseImageURL: "https://cdn.superme.al/p/ae",
      baseURL: "https://www.supermeal.ae",
      clientId: "243E1018-0F45-49EB-BDBD-C0473E27CA88",
      clientSecret : "f5242dfa70c1f9c9f7c",
 	 distanceUnit: "mile(s)",
 	countryCode: "+971",
 	timeZone : TimeZone.aeTimeZone,
 	DateFormat:"DD/MM/YYYY",
    currencySymbol: "AED ",
 	SupportEmail:'support@supermeal.ae',
 	GoogleAPIkEY: 'AIzaSyB7FZe7D20kpyiNs55Ab1WU6J1Bl6Y7Nds',
 	Platform:'core', //superShoply //core
	DefaultLatLong : [25.19284309,55.27899696]	
  };
 

 
//const BaseURLs = {
//   coreApiURL : "https://api.test.supermeal.ae/api",
//   baseImageURL: "https://cdn.superme.al/s/ae",
 //  baseURL: "https://test.supermeal.ae",
//   clientId: "postman",
 //   clientSecret : "test123",
//	currencySymbol : "AED ",///	distanceUnit: "km",
//   countryCode: "+971",
 //  DateFormat:"DD/MM/YYYY",
//	timeZone : TimeZone.aeTimeZone,
//	SupportEmail:'Support@supermeal.ae',
//	GoogleAPIkEY: 'AIzaSyB7FZe7D20kpyiNs55Ab1WU6J1Bl6Y7Nds',
//	Platform:'core',
//	DefaultLatLong : [25.19284309,55.27899696]	

//};


//  const BaseURLs = {
//       coreApiURL : "https://api.test.supershoply.com/api",
//       baseImageURL: "https://cdn.superme.al/s/shoply",
//       baseURL: "https://test.supershoply.com",
//       clientId: "postman",
//       clientSecret : "test123",
// 	  distanceUnit: "mile(s)",
//  	  countryCode: "+44",
// 	  DateFormat:"DD/MM/YYYY",
//  	  timeZone : TimeZone.ukTimeZone,
//       currencySymbol: "£",
//      SupportEmail:'Support@supermeal.co.uk',
//      GoogleAPIkEY: 'AIzaSyB7FZe7D20kpyiNs55Ab1WU6J1Bl6Y7Nds',
//      Platform:'superShoply' //superShoply //core
//   };

 export const parentCountryCode = "+44"

const GlobalData = {

    restaurants_data: {

        Supermeal_dev: {
            isUk: true,
            coreApiURL : BaseURLs.coreApiURL,
            clientApi : { ClientId: BaseURLs.clientId, ClientSecret: BaseURLs.clientSecret},
            baseUrl: BaseURLs.baseURL, 
            baseImageUrl: BaseURLs.baseImageURL,
            currency: BaseURLs.currencySymbol,
            decimalPlaces: 2,
            csvSeperator: '^^^',
            distanceUnit: BaseURLs.distanceUnit,
            timezone: BaseURLs.timeZone,
            countryCode: BaseURLs.countryCode,
            dateFormat:BaseURLs.DateFormat,
            DeliveryCharges: "0",
            DeliveryTime: "45",
            MinimumDeliveryOrder: "10",
            zoneLimit: 5,
			Google_Api_Key: BaseURLs.GoogleAPIkEY,
            SupportEmail: BaseURLs.SupportEmail,
            Google_Lat_Long: [51.507351,-0.127758],
            Delivery_Zone_Color_Palette : ["#27ae60","#3498db","#f1c40f","#e74c3c","#8e44ad"],
            Campaign_Logo_Image_Width_Height : ["175","55"],
            Campaign_Main_Banner_Width_Height : ["600","318"],
            Campaign_Sub_Banner_Width_Height : ["1920","150"],
            Campaign_App_Sub_Banner_Width_Height : ["600","100"],
            Campaign_Food_Image_Width_Height : ["750","750"],
            Campaign_Background_Image_Width_Height : ["1920","1080"],
            Campaign_App_Background_Image_Width_Height : ["1000","1000"], 
            Category_Image_Width_Height : ["750","150"], 
            Product_Image_Width_Height : ["500","500"],
            Enterprise_Bank_Details : "Account Name:;Account Number:;Bank Name:;Branch Name:;Branch Address:;Swift Code:;",
            Item_Filters : "Brand::;Tags::;Dietry::;VideoURL::;",
            Session_Expire_In_Hours: 24,
            Platform: BaseURLs.Platform,
            PreOrderAcceptDuration: 90,
        }

    }

}

export default GlobalData
